<template lang="">
  <div class="p-2">
    <div class="card">
      <h2>codes</h2>
      <h3>Nouveau code</h3>
      <table v-if="displayType === 'a'" @submit.prevent="addCategorie">
        <thead>
          <tr>
            <th>Code</th>
            <th>Debut</th>
            <th>Fin</th>
            <th>Taux reduction</th>
            <th>Active</th>
            <th>Promotion</th>
            <th>Ajouter</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                class="form-control"
                max="100"
                min="1"
                v-model="newCode.code"
              />
            </td>
            <td>
              <input
                type="date"
                v-model="newCode.validFrom"
                formatter=""
                class="form-control"
              />
            </td>
            <td>
              <input
                type="date"
                v-model="newCode.validTo"
                formatter=""
                class="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                v-model="newCode.discount"
                class="form-control"
                min="1"
              />
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  @change="toggle(c, 'active')"
                  v-model="newCode.active"
                  class="form-check-input"
                />
              </div>
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  @change="toggle(c, 'promotion')"
                  v-model="newCode.promotion"
                  class="form-check-input"
                />
              </div>
            </td>
            <td>
              <button class="btn btn-primary btn-sm" type="button" @click="add">
                Ajouter
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex  mt-1 mb-1 ">
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            id="gridRadios1"
            value="v"
          />
          <label class="form-check-label" for="gridRadios1">
            Visualisation
          </label>
        </div>
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            name="gridRadios"
            id="gridRadios2"
            value="e"
          />
          <label class="form-check-label" for="gridRadios2">
            Edition
          </label>
        </div>
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            name="gridRadios"
            id="Ajouter"
            value="a"
          />
          <label class="form-check-label" for="Ajouter">
            Nouvelle
          </label>
        </div>
      </div>
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Debut</th>
            <th>Fin</th>
            <th>Taux reduction</th>
            <th>Active</th>
            <th>Promotion</th>
            <th v-if="displayType === 'e'">Editer</th>
            <th>Sup</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in codes" :key="c.id">
            <td>{{ c.id }}</td>
            <td>
              <input
                type="text"
                class="form-control"
                max="100"
                min="1"
                v-model="c.code"
                :disabled="displayType !== 'e'"
              />
            </td>
            <td>
              <input
                :disabled="displayType !== 'e'"
                type="date"
                v-model="c.validFrom"
                formatter=""
                class="form-control"
              />
            </td>
            <td>
              <input
                :disabled="displayType !== 'e'"
                type="date"
                v-model="c.validTo"
                formatter=""
                class="form-control"
              />
            </td>
            <td>
              <input
                :disabled="displayType !== 'e'"
                type="number"
                v-model="c.discount"
                class="form-control"
                min="1"
              />
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  :disabled="displayType !== 'e'"
                  type="checkbox"
                  @change="toggle(c, 'active')"
                  v-model="c.active"
                  class="form-check-input"
                />
              </div>
            </td>
            <td>
              <div class="form-check form-switch">
                <input
                  :disabled="displayType !== 'e'"
                  type="checkbox"
                  @change="toggle(c, 'promotion')"
                  v-model="c.promotion"
                  class="form-check-input"
                />
              </div>
            </td>
            <td v-if="displayType === 'e'">
              <button
                class="btn btn-primary btn-sm"
                @click="() => edit(c)"
                :disabled="displayType === 'v'"
              >
                <i class="far fa-edit"></i>
              </button>
            </td>
            <td>
              <button class="btn btn-danger btn-sm" @click="() => del(c.id)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codes: [],
      displayType: "v",
      newCode: {
        code: "",
        validFrom: null,
        validTo: null,
        discount: 1,
        active: true,
        promotion: false,
      },
    };
  },
  methods: {
    async loadCodes() {
      await this.$store.dispatch("loadCodes").then((codes) => {
        this.codes = codes;
      });
    },
    async edit(c) {
      await this.$http.put(`/promocode/${c.id}`, c).then(() => {
        this.$store.state.codes.updated = false;
        this.$toast.success("success!");
        this.loadCodes();
      });
    },
    async del(id) {
      if (window.confirm("Etes vous sure de cette action?"))
        await this.$http.delete(`/promocode/${id}`).then(() => {
          this.$store.state.codes.updated = false;
          this.$toast.success("success!");
          this.loadCodes();
        });
    },
    async add() {
      await this.$http.post("/promocode/list-create", this.newCode).then(() => {
        this.$store.state.codes.updated = false;
        this.$toast.success("success!");
        this.displayType = "v";
        this.loadCodes();
      });
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.loadCodes();
    });
  },
};
</script>
<style lang=""></style>
